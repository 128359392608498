<template>
  <div>
    <el-dialog
      v-loading="adding"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      id="supple"
      :show-close="false"
    >
      <div class="part-check">
        <span
          >基础信息表单
          <span class="filed">以下加<span class="fieldbase">*</span>信息都为必填项</span>
        </span>
        <img class="part-close" src="@/assets/test/part-close.png" alt @click="cancel" />
      </div>
      <div class="content">
        <div class="box">
          <div class="name field">项目名称：</div>
          <div class="info">
            <el-input v-model.trim="info.projectName" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">项目详细坐落地址：</div>
          <div class="info">
            <el-input v-model.trim="info.projectAddress" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">项目土地面积(㎡)：</div>
          <div class="info">
            <el-input
              v-model.trim="info.landArea"
              placeholder="请输入"
              @input="(v) => (info.landArea = v.replace(/[^\d.]/g, ''))"
            ></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">项目可售建筑面(㎡)：</div>
          <div class="info">
            <el-input
              v-model.trim="info.saleableLandArea"
              placeholder="请输入"
              @input="(v) => (info.saleableLandArea = v.replace(/[^\d.]/g, ''))"
            ></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">项目土地价款(元)：</div>
          <div class="info">
            <el-input
              v-model.trim="info.landPrice"
              placeholder="请输入"
              @input="(v) => (info.landPrice = v.replace(/[^\d.]/g, ''))"
            ></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">计税方式：</div>
          <div class="info">
            <el-radio v-model="info.taxCode" label="1">一般计税</el-radio>
            <el-radio v-model="info.taxCode" label="2">简易计税</el-radio>
          </div>
        </div>
        <div class="box">
          <div class="name field">是否已交房：</div>
          <div class="info">
            <el-radio-group v-model="info.roomBuildingCompleted">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="box" v-if="info.roomBuildingCompleted == 1">
          <div class="name field">交房日期：</div>
          <div class="info">
            <el-date-picker
              align="center"
              v-model="info.roomBuildingCompletedDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="box">
          <div class="name field">土增税是否已清算：</div>
          <div class="info">
            <el-radio-group v-model="info.liquidation">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="box" v-if="info.liquidation == 1">
          <div class="name field">清算日期：</div>
          <div class="info">
            <el-date-picker
              align="center"
              v-model="info.liquidationDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="box">
          <div class="name">备案单价(区间)：</div>
          <div class="info">
            <div class="bettow">
              <div>
                <el-input
                  v-model.trim="info.projectMinMoney"
                  placeholder="请输入"
                  @input="(v) => (info.projectMinMoney = v.replace(/[^\d.]/g, ''))"
                ></el-input>
              </div>
              <div class="horizontal">-</div>
              <div>
                <el-input
                  v-model.trim="info.projectMaxMoney"
                  placeholder="请输入"
                  @input="(v) => (info.projectMaxMoney = v.replace(/[^\d.]/g, ''))"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="box box-bottom">
          <div class="name">项目所属区域：</div>
          <div class="info area">
            <el-radio v-model="info.projectArea" label="1"
              >开发项目位于省/自治区/直辖市和计划单列市人民政府所在地城市城区和郊区</el-radio
            >
            <el-radio v-model="info.projectArea" label="2"
              >开发项目位于地级市城区及郊区</el-radio
            >
            <el-radio v-model="info.projectArea" label="3">开发项目位于其他地区</el-radio>
            <el-radio v-model="info.projectArea" label="4"
              >属于经济适用房/限价房和危改房</el-radio
            >
          </div>
        </div>
        <div class="box">
          <div class="name">是否甲供材：</div>
          <div class="info">
            <el-radio v-model="info.armorForMaterials" label="1">是</el-radio>
            <el-radio v-model="info.armorForMaterials" label="0">否</el-radio>
          </div>
        </div>
      </div>
      <div class="base-Btn">
        <div class="info" v-if="tips">提示：您有信息尚未填写</div>
        <div v-else class="tips">1</div>
        <div class="btn" @click="cancel">取消</div>
        <div class="btn submit" @click="submit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInfoByCompanyNsrsbm, addInfoBy, editInfoBy } from "@/api/api";
const moment = require("moment");
import { mapState } from "vuex";
export default {
  data() {
    return {
      info: {
        armorForMaterials: "",
        taxCode: "",
        projectArea: "",
        roomBuildingCompleted: null,
        liquidation: null,
      },
      adding: false,
      type: 1,
      //   projectName: "",
      //   projectAddress: "",
      //   armorForMaterials: "0",
      //   projectMinMoney: "",
      //   projectMaxMoney: "",
      //   taxCode: "0",
      //   projectArea: "0",
      tips: false,
      areaList: [
        "开发项目位于省/自治区/直辖市和计划单列市人民政府所在地城市城区和郊区",
        "开发项目位于地级市城区及郊区",
        "开发项目位于其他地区",
        "于经记适用房/限价房和危改房",
      ],
      dialogVisible: false,
    };
  },
  mounted() {
    // this.getBaseInfo();
  },
  computed: {
    ...mapState("oem", ["auth"]),
    uuid() {
      return this.auth.preInspectId;
    },
  },
  // props: {
  //   zq: [String],
  // },
  methods: {
    getBaseInfo() {
      this.dialogVisible = true;
      getInfoByCompanyNsrsbm(this.uuid).then((res) => {
        // console.log(res);
        this.info = res.data;
        if (this.info.roomBuildingCompletedDate) {
          this.info.roomBuildingCompletedDate = moment(
            this.info.roomBuildingCompletedDate
          ).format("YYYY-MM-DD");
        }
        if (this.info.liquidationDate) {
          this.info.liquidationDate = moment(this.info.liquidationDate).format(
            "YYYY-MM-DD"
          );
        }
      });
    },
    checkAll() {
      this.tips = false;
      if (!this.info.projectName) {
        this.tips = true;
        return false;
      }
      if (!this.info.projectAddress) {
        this.tips = true;
        return false;
      }
      if (!this.info.landArea) {
        this.tips = true;
        return false;
      }
      if (!this.info.saleableLandArea) {
        this.tips = true;
        return false;
      }
      if (!this.info.landPrice) {
        this.tips = true;
        return false;
      }
      if (!this.info.taxCode) {
        this.tips = true;
        return false;
      }
      if (this.info.roomBuildingCompleted == null) {
        this.tips = true;
        return false;
      }
      // 是否已交房
      if (this.info.roomBuildingCompleted == 1) {
        if (!this.info.roomBuildingCompletedDate) {
          this.tips = true;
          return false;
        }
      }
      if (this.info.liquidation == null) {
        this.tips = true;
        return false;
      }
      // 土增税是否已清算
      if (this.info.liquidation == 1) {
        if (!this.info.liquidationDate) {
          this.tips = true;
          return false;
        }
      }
      if (this.info.projectMinMoney) {
        if (Number(this.info.projectMinMoney) >= Number(this.info.projectMaxMoney)) {
          this.$message.error("请注意区间大小");
          // this.tips = true;
          return false;
        }
      }
      return true;
    },
    submit() {
      if (!this.checkAll()) {
        return;
      }
      this.adding = true;
      this.add();
    },
    add() {
      let params = {
        armorForMaterials: this.info.armorForMaterials,
        projectAddress: this.info.projectAddress,
        projectArea: this.info.projectArea,
        projectMaxMoney: this.info.projectMaxMoney,
        projectMinMoney: this.info.projectMinMoney,
        projectName: this.info.projectName,
        taxCode: this.info.taxCode,
        programId: this.uuid,
        zq: this.$store.state.oem.zq,
        roomBuildingCompleted: this.info.roomBuildingCompleted,
        roomBuildingCompletedDate: this.info.roomBuildingCompletedDate,
        liquidation: this.info.liquidation,
        liquidationDate: this.info.liquidationDate,
        landPrice: this.info.landPrice,
        landArea: this.info.landArea,
        saleableLandArea: this.info.saleableLandArea,
      };
      editInfoBy(params).then((res) => {
        this.adding = false;
        if (res.code == 200) {
          this.dialogVisible = false;
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  .box {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .name {
      width: 150px;
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }

    .info {
      >>> .el-input__inner {
        height: 36px;
        width: 360px;
      }

      .bettow {
        display: flex;
        align-items: center;

        >>> .el-input__inner {
          height: 36px;
          width: 165px;
        }

        .horizontal {
          margin: 0 11px;
        }
      }
    }

    .area {
      display: flex;
      flex-direction: column;

      >>> .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        vertical-align: top;
        display: inline-block;
        width: 335px;
      }

      >>> .el-radio {
        margin-bottom: 10px;
      }
    }
  }

  .box-area {
    align-items: flex-start;
  }

  .box-bottom {
    margin-bottom: 0
  }

  .box:first-child {
    margin-top: 6px;
  }
}

>>> #supple .el-dialog {
  width: 540px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #supple .el-dialog__header {
  padding: 0;
}

>>> #supple .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 768px) {
  >>> #supple .el-dialog {
    width: 360px;
 }
  .content .box .info {
   >>> .el-input__inner {
     height: 36px;
     width: 160px;
   }

   .bettow {
     display: flex;
     align-items: center;

     >>> .el-input__inner {
       height: 36px;
       width: 110px;
     }

     .horizontal {
       margin: 0 11px;
     }
   }
  }

  .content .box .area {
    width: 250px;
  }

  .content .box .area {
    >>> .el-radio__label {
      width: 200px;
    }
  }

  .container .base-Btn .info {
    font-size: 12px;
  }
}

.part-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: bold;

  .filed {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
}

.part-close {
  padding-right: 32px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
  top: 1px;
}

.base-Btn {
  display: flex;
  align-items: center;
  height: 40px;
  border-top: 1px solid #eeeeee;

  .tips {
    color: transparent;
    flex: 1;
  }

  .info {
    color: red;
    font-size: 14px;
    flex: 1;
    margin-left: 30px;
  }

  .btn {
    width: 56px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #2F54EB;
    color: #2F54EB;
    cursor: pointer;
  }

  .submit {
    border: 1px solid #2F54EB;
    background: #2F54EB;
    color: #fff;
    width: 95px;
  }
}

.fieldbase {
  color: red;
}
</style>
