<template>
    <div>
        <el-dialog :visible.sync="centerDialogVisible" title="授权协议" width="1000px" center>
            <div class="fixed-content">
                <div class="title">
                    本《授权协议》适用于杭州税安科技有限公司（以下简称税安科技）提供的所有服务，在您使用税安科技相关服务之前，请您务必审慎阅读、充分理解本《授权协议》中与您的权益（可能）存在重大关系的条款，税安科技已采用加粗字体或下划线来特别提醒您，请您留意重点查阅。当您使用税安科技的服务时，您已同意本《授权协议》。请注意税安科技会不时地检查并更新政策，因此有关的措施会随之变化。税安科技恳请您定期光顾本页面，以确保对本《授权协议》最新版本始终保持了解。
              </div>
                <div class="content">
                    <div class="oneLeverTitle">1、数据收集：</div>
                    <div class="oneLeverContent">
                        （1）您通过填写电子税务局账号密码的方式，自愿授权税安科技采集您在使用服务期间的
                        纳税申报数据，包含但不限于进销项发票数据、所得税纳税申报表、增值税纳税申报表、小
                        税种纳税申报表、财务报等。<br />
                        （2）您通过在平台上传纳税申报表，自愿授权税安科技采集您在使用服务期间的纳税申报
                        数据和财务数据，包含但不限于所得税纳税申报表、增值税纳税申报表、小税种纳税申报表、
                        财务报表等。数据内容包括但不限于您通过财务软件获取的数据、导入 Excel
                        表格所载明的 数据、通过 HTML 获取的数据、或通过手动填写的数据。
                    </div>
                    <div class="oneLeverTitle">2、数据用途</div>
                    <div class="oneLeverContent">
                        以上数据，将作为您所在的企业数据展示使用及风险数据评估使用。在您授权的情况下，以上数据可应用于税安科技其他产品或税安科技第三方合作伙伴。在您未授权的情况下，税安科技其他产品或第三方合作伙伴将无权使用您的数据。税安科技将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息，但仍需您同意第三方合作伙伴的相授权协议。
                    </div>
                    <div class="oneLeverTitle">3、信息安全</div>
                    <div class="oneLeverContent">
                        税安科技为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或
                        披露。<br />
                        （1）税安科技严格遵守法律法规保护您的通信秘密。<br />
                        （2）税安科技将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，使
                        用加密技术（例如，TLS、SSL）、匿名化处理等手段来保护您的信息。<br />
                        （3）税安科技建立专门的管理制度、流程和组织确保信息安全。例如，严格限制访问信息
                        的人员范围，要求他们遵守保密义务，并进行审查。<br />
                        （4）若发生信息泄露等安全事件，税安科技会启动应急预案，阻止安全事件扩大，并以推
                        送通知、公等形式告知您。<br />
                        （5） 尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您
                        理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安
                        全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入税安科技的服务所用
                        的系统和通讯网络，有可能因税安科技可控范围外的因素而出现问题。因此，您应采取积极
                        措施护您的信息安全
                    </div>
                    <div class="oneLeverTitle">4、免责声明</div>
                    <div class="oneLeverContent">
                        （1）您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务
                        发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客
                        观事件，包括但不限于自然灾害以及社会事件如战争、动乱、政府行为等。出现上述情况时，
                        税安科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失税安
                        科技在法律允许的范围内免责。<br />
                        （2）您理解并同意，您使用的产品中的部分服务由第三方独立开发并提供，在您选择使用
                        此类服务之前，请您关注其中可能存在的风险，并遵守第三方制定的相应使用规则。本《授
                        权协议》仅适用于税安科技所收集、保存、使用、共享、披露信息，并不适用于任何第三方
                        提供的服务或第三方的信息使用规则，税安科技对任何第三方使用由您提供的信息不承担任
                        何责任。<br />
                        （3）在任何情况下，税安科技及公司的董事、高级职员、雇员、关联公司、代理、承包商
                        或许可方均不对由您对有关服务的使用或无法使用所引起的任何间接、附带、惩罚性、特殊
                        或后果性的不可预期的损害赔偿承担责任。您已了解，您应自担服务使用风险，并且税安科
                        技将按“现状”提供服务，服务“可能存在各种缺陷”且只提供“目前可用功能”。税安科
                        技不保证服务所提供的信息准确性或时性。<br />
                        （4）本系统基于您的授权获取相关数据，并以此为基础提供相应服务。我们不会主动储存您的数据，您需要通过软件选择数据保留的期限，系统将在到期后自动清除。
                    </div>
                    <div class="oneLeverTitle">5、其他</div>
                    <div class="oneLeverContent">
                        （1）本协议自您确认并接受协议内容后之日起生效，如您未同意本协议，请勿进行后续操
                        作，并立即停止授权。您将无法正常使用税安科技提供的服务。<br />
                        （2）税安科技承诺，在您使用服务期间所采集的信息，您停止使用服务后税安科技也将及
                        时删除或销毁此信息，不将您的信息挪作他用。<br />
                        （3）当您因为《授权协议》或相关服务与税安科技产生任何纠纷时，双方应首先协商友好
                        解决；若不能协商解决，您同意将争议提交至税安科技所在地人民法院管辖。
                    </div>

                </div>
            </div>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="read" type="primary" :disabled="dialogCommand">我已阅读并同意协议 <span v-if="count>0">{{ '('+ count+')' }}</span></el-button>
               
              </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>


export default {
    name: 'agreementModal',





    data(){
      return{
        centerDialogVisible:false,
        interval:null,
        count:10,
        centerDialogVisible:false,
      }
    },
    methods:{
      openDialog(){
          clearInterval(this.interval)
          this.centerDialogVisible = true
          this.count=10
          this.interval=setInterval(()=>{
            this.count--
          },1000)
      },
      read(){
          this.$emit('read')
          this.centerDialogVisible=false
        }

    },
   watch:{
    count(new1){
      if(new1==0){
        clearInterval(this.interval)

      }
    },
   },
    computed:{
      dialogCommand(){
        if (this.count==0){

          return false
          }
          return true
              },
    },
    
}

</script>

<style scoped lang="stylus">
.dialog-footer{
  text-align: center;
}
.login-wrapper {
width:1100px;
height: 520px;
margin:auto;
position relative

.tab_box {
  padding: 10px 50px 30px;
  display: flex;
  flex-direction: column
  align-items: flex-start;
  box-sizing border-box;
  position: absolute;
  right: 0;
  width: 420px;
  height: 520px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 1px rgba(0,0,0,0.1);
  border-radius: 12px 12px 12px 12px;
  overflow hidden
  #tab{
    margin-top: 12px;
    width: 100%;
    .codeBtn{
      position: absolute;
      right: 0px;
      top: 9px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #165DFF;
      cursor pointer
    }
    .codeBtnTime{
      position: absolute;
      right: 0px;
      top: 9px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #A4A6A9;
    }
    .iconfont{
      position: absolute;
      left: 8px;
      top: 2px;
      font-size: 15px;
      z-index: 2;
      color: #1D2129;
    }
    .icon-icon_xianshi,.icon-icon_yincang{
      font-size: 15px;
      color: #1D2129;
      position: absolute;
      left: 297px;
      top: 2px;
    }
    .icon-icon_xianshi,.icon-icon_yincang:hover{
      cursor pointer
    }
  }
  .goRegister{
    position: absolute;
    width: 120px;
    height: 120px;
    background: #D3E0FD;
    border-radius:50%;
    top:-60px;;
    right:-60px;
    cursor pointer
    span{
      position: absolute;
      bottom: 25px;
      left: 18px
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #165DFF;
      line-height: 22px;
    }
  }

}
}
.zcuser1 {
  margin-top: 23px !important;
}

.btnsendcode {
  position: absolute;
  right: 0;
  top: -16px;
  background: #fff;
  border-radius: 4px;
  color: #2F54EB;
  width: 102px;
  padding: 12px 0px;
  border: 1px solid #2F54EB;

  &:hover, &:focus, &:active {
    background: #fff;
    border-color: #fff;
  }

  &.active {
    // background: #D1D1D1;
    // color: #E6E6E6;
    // border-color: #D1D1D1;
  }
}

>>> .usernameinput {
  .el-input__inner {
    height: 40px;
    border-radius: 4px;
    text-indent: 10px;
  }
}

>>>.rememberpw {
  div{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #1D2129;
    span{
      color:#165DFF !important;
    }
  }

  .el-checkbox__inner {
    border-color: #A4A6A9
    width: 16px;
    height: 16px;
    border-radius: 2px 2px 2px 2px;
    &::after {
    }
  }
  .el-checkbox__inner:hover{
    border-color:#165DFF
    }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2F54EB;
    border-color: #2F54EB;
  }

  .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .el-checkbox__inner::after{
    left: 5px;
  }

}


.forget {
  text-align: left;
  margin-top: -11px
}

.btn-send-sms {
  margin-top: 16px;
}

>>>.el-input__inner {
  padding-left: 30px;
  color: #1D2129;
}

>>>.el-tabs__nav-wrap::after {
  content: none;
}

>>>.el-tabs {
  width: 100%;
  margin: 0 auto;
}

>>>.el-tabs__nav-wrap {
  width: 100%;
  margin: 0 auto;
}




.loginitem {
margin-top: 16px;

}

.loginbtn1 {
  height: 44px;
  background: #2663F6;
  width: 100%;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}
.loginbtn1:hover{
  background: #165DFF;
  color:#fff
}
.containernew {
  width: 100%;
  margin: 0 auto;

  >>>.a-form-item {
    margin-bottom: 24px;
  }

  >>>.a-form-item:nth-child(2) {
    margin-bottom: 20px;
  }

  .baseloginicon {
    position: absolute;
    left: 16px;
    color: #999999;
    width: 16px;
    height: 16px;
  }
}

//
.el-form-item {
  margin-bottom: 24px;
}
>>>.ant-tabs-nav{
  margin-bottom: 26px;
}
>>>.el-input__wrapper{
  padding: 3px 4px;
  box-shadow: 0 0 0 1px #fff inset;
  border-radius:0
  color:#F54242;
  border-bottom: 1px solid #dedede;
}
>>>.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 0 0 0px #fff inset;
  border-bottom: 1px solid #F54242;
}
>>>.el-form-item__error {
  color: #F54242;
  font-size: 12px;
  line-height: 1;
  padding-top: 3px;
  position: absolute;
  top: 100%;
  left: 10px;
}
>>>.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #fff inset;
  border-bottom: 1px solid #165DFF !important;
}
>>>.el-form-item.is-error .el-input__wrapper:hover {
  box-shadow: 0 0 0 0px var(--el-color-danger) inset;
}
>>>.el-input__wrapper:hover {
  box-shadow: 0 0 0 0px #fff inset;
  border-bottom: 1px solid #A4A6A9;
}
>>>.el-form-item.is-error .el-input__wrapper.is-focus{
  box-shadow: 0 0 0 1px #fff inset!important;
}
>>>.ant-tabs-top >.ant-tabs-nav::before{
  border-bottom:1px solid #fff;
}
>>>.ant-tabs {
  font-weight: bold

}
>>>.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #165DFF;
}
>>>.ant-tabs-tab{
  font-size:16px;
}
.placeholder{
  position absolute
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #A4A6A9;
  line-height: 12px;
  top: 40px;
  left: 10px;
  height: 22px;
  background: white;
  z-index: 12;
}
>>>.el-checkbox{
  //height:0;
  //margin-top:18px;
}
>>>.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 0 1px 24px 24px;
}
>>>.el-dialog__header {
  padding-bottom: 20px;
  padding-top: 24px;
  .el-dialog__title{
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
}
.fixed-content{
  height: 580px;
  overflow: auto;
  .title{
    margin-right:19px;
    color: #1D2129;
  }
  .content{
    margin-right:19px;
    font-size: 14px;
    color: #1D2129;
    .oneLeverTitle{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #1D2129;
      margin:10px 0;

    }
    .oneLeverContent{
      margin-bottom:10px
    }
  }
}
>>>.el-overlay-dialog {
  //top: -40px;
  border-radius: 8px 8px 8px 8px;
}
>>>.el-dialog--center {
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
}
.fixed-content::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 6px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
  position: relative;
  right: 4px;
}
.fixed-content::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: #606368;
}

.fixed-content::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  //-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: #fff;
}
>>>.ant-btn-default{
  border:none;
  font-size:16px;
}
>>>.el-dialog__headerbtn .el-dialog__close{
  font-size:20px;
  color:#606368;
  top: 6px;
}
</style>
